.tech-stack {
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  background: #fff;
  text-align: left;

  &:before {
    position: absolute;
    z-index: 1;
    content: '';
    width: calc(50% + 200px);
    height: calc(100% + 60px);
    top: 50%;
    left: -200px;
    background: linear-gradient(to bottom left, #000428, #004e92);
    transform: skew(-10deg) translateY(-50%);
  }

  &:after {
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    right: -50px;
    width: 700px;
    height: 600px;
    $bg-color: #fff;
    $dot-color: hsl(256, 33, 70);
    // opacity: 0.8;

    // Dimensions
    $dot-size: 1px;
    $dot-space: 22px;

    background: linear-gradient(
          90deg,
          $bg-color ($dot-space - $dot-size),
          transparent 1%
        )
        center,
      linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
      $dot-color;
    background-size: $dot-space $dot-space;
  }

  .sec-title {
    z-index: 1;
    color: #fff;
    &:before {
      background: #fff;
      left: 0;
      transform: none;
    }
  }

  p {
    position: relative;
    color: #fff;
    z-index: 2;
    margin-left: 0;
    max-width: 600px;
    margin-bottom: 30px;
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    padding-right: 300px;
    position: relative;
    z-index: 2;
    display: flex;
    margin-left: -8px;

    .tech-card {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
      border-radius: 4px;
      background: #fff;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px,
        rgba(240, 46, 170, 0.4) -5px 5px, rgba(240, 46, 170, 0.3) -10px 10px,
        rgba(240, 46, 170, 0.2) -15px 15px, rgba(240, 46, 170, 0.1) -20px 20px,
        rgba(240, 46, 170, 0.05) -25px 25px;
      padding: 16px 40px;
      font-size: 13px;
      color: #222;
      margin: 8px;
      position: relative;
      cursor: zoom-in;
      transition: all 0.2s ease-in-out;
      &:hover {
        transform: scale(2);
        z-index: 2;
      }
    }
  }
}
