.latest-blog-posts {
  background: #fff;

  &__wrap {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
  }

  .card {
    display: flex;
    flex-direction: column;
    text-align: left;
    text-decoration: none;
    color: #004e92;
    margin-left: 15px;
    margin-right: 15px;
    width: 360px;
    border: 0;
    background-color: transparent;

    &__image-wrap {
      position: relative;
      height: 0;
      padding-bottom: 69.44%;
      background-color: #cecece;

      &-outer {
        width: 100%;
        border-radius: 15px;
        overflow: hidden;
        margin-bottom: 12px;
      }

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 120%;
        height: 120%;
        transform: translate(-50%, -50%) scale(0.85);
        transition: all 0.2s ease-in-out;
      }

      &:hover {
        img {
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }

    &__content {
      padding-left: 10px;
      padding-left: 10px;
    }

    &__posted-on {
      margin-top: 2px;
      color: #000428;
      font-size: 12px;
    }
  }
}
