.footer {
  background-color: #000428;
  text-align: center;
  padding: 10px 15px;

  a {
    color: #fff;
    text-decoration: none;
    font-weight: 300;
  }
}
