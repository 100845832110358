*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #f2edf5;
  font-family: 'Poppins', sans-serif;
}

.main {
  max-width: 100vw;
  overflow-x: hidden;
  border: 10px solid #fff;
}

.container {
  width: 1500px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.sec-title {
  position: relative;
  font-size: 32px;
  color: #333;
  margin-bottom: 30px;
  padding-bottom: 18px;
  &:before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    width: 60px;
    height: 5px;
    background: linear-gradient(to top left, #000428, #004e92);
  }
}

section {
  padding-top: 150px;
  padding-bottom: 150px;
  text-align: center;

  .sec-body {
    font-size: 20px;
    font-weight: 300;
    color: #222;
    max-width: 840px;
    margin-left: auto;
    margin-right: auto;
    &:not(:last-of-type) {
      margin-bottom: 15px;
    }
  }
}

.main-cta {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 1;
  margin-top: 40px;
  text-decoration: none;
  border-radius: 4px;
  background: #004e92;
  color: #fff;
  height: 50px;
  width: 230px;
  font-weight: 300;
  letter-spacing: 0.8px;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: #000428;
  }
}
