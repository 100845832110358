.hero {
  position: relative;
  background: rgb(206, 30, 39);
  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to top left,
      rgba(#000428, 0.8),
      rgba(#004e92, 0.8)
    );
  }

  &:after {
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    left: -100px;
    width: 600px;
    height: 600px;
    $bg-color: rgb(206, 30, 39);
    $dot-color: hsl(256, 33%, 70%);
    opacity: 0.8;

    // Dimensions
    $dot-size: 3px;
    $dot-space: 25px;

    background: linear-gradient(
          90deg,
          $bg-color ($dot-space - $dot-size),
          transparent 1%
        )
        center,
      linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
      $dot-color;
    background-size: $dot-space $dot-space;
  }

  img {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 60%;
    height: calc(100% - 60px);
    object-fit: cover;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  }

  .container {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    height: 800px;
    padding-bottom: 100px;
  }

  &__content {
    max-width: 800px;
  }

  h1,
  p {
    color: #fff;
    padding-left: 10vw;
  }

  h1 {
    font-size: 60px;
    margin-bottom: 15px;
    font-weight: 800;
    letter-spacing: 2px;
  }

  p {
    font-size: 22px;
    line-height: 1.5;
    font-weight: 200;
  }
}
